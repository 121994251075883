export default {
  "site": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "pagesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Save Broadcast app has many features, and we showcase some of its features."])},
    "appPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Pages"])},
    "Awamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designed and programmed by Awamer Network"])},
    "cvCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved to Save"])},
    "downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
    "companiesNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered Companies"])},
    "userNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Beneficiaries"])},
    "clientNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Clients"])},
    "statDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here we display the application statistics."])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Statistics"])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Vision"])}
  },
  "dash": {
    "addJobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Job Posting"])}
  },
  "nav": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "addAds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Job Posting"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Orders"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Settings"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Information"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Permissions"])},
    "subs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])}
  },
  "header": {
    "dash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome, welcome back"])}
  },
  "home": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We display some statistics about the dashboard."])},
    "sta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "employment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment"])},
    "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliers that accepted"])},
    "appliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliers for the company"])},
    "rateDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average match rate for hired applicants."])},
    "avr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
    "applyOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliers for Advertisment number : "])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertisment"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "job_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["job Application"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])}
  },
  "search": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "searchEmp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for Employees here"])},
    "jobName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
    "spa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialization"])},
    "qual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational Qualification"])},
    "jobType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Type"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "exper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "cer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Certifications"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field of Work"])},
    "do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "most": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Matched"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the profiles of the most matched employees."])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Profile"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "map_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "profileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Data"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])}
  },
  "job": {
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add a job posting from here"])},
    "namePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Job Title"])},
    "chooseType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Job Type"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialization Name"])},
    "chooseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Specialization Name"])},
    "cat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Category"])},
    "chooseCat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the job category"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "chooseGender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Gender"])},
    "chooseQual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the required qualification"])},
    "chooseExper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the required experience"])},
    "res": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duties and Responsibilities"])},
    "resPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Duties and Responsibilities"])},
    "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
    "ifExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If Available"])},
    "chooseSkill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the required skills"])},
    "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching Percentage"])},
    "choosePer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the matching percentage"])},
    "chooseCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the city"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residence Place"])},
    "cer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Certifications"])},
    "cerDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the professional certifications"])},
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Conditions"])},
    "conPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Job Conditions"])},
    "chooseEmp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the job category"])}
  },
  "order": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Orders"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can view all job postings and job orders that have been applied for."])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show More"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full-Time"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part-Time"])},
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Work"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
  },
  "set": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Information"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can edit all your basic information here"])},
    "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upload a cover image"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishment Name"])},
    "namePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Establishment Name"])},
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])},
    "userNamePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter user name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishment type"])},
    "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governmental Establishment"])},
    "type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Establishment"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishment Activity"])},
    "activePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Establishment Activity"])},
    "res": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible Person's Name"])},
    "resPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Responsible Person's Name"])},
    "com": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Registration Number"])},
    "comPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Commercial Registration Number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Email"])},
    "resPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["responsible person"])},
    "resPersonPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter responsible person"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "phonePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Mobile Number"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "regionPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the region"])},
    "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "CityPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the City"])},
    "comp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Description"])},
    "compPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Company Description here"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Account"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "haveAnAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])}
  },
  "emp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a New Employee"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Data"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Employee"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this employee"])},
    "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee deleted successfully"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Permissions"])},
    "addEmp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Employee"])},
    "empName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Name"])},
    "namePlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Employee Name"])},
    "trans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension Number"])},
    "transPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Extension Number"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])}
  },
  "sub": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Name"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Details"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Duration"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Status"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Expiry Date"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Status"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
    "renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Payment Method"])},
    "payNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "emailCon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact via Email"])},
    "whats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact via WhatsApp"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact via Phone"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can send a message from here"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "namePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your name here"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Message"])},
    "messagePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your message here"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
    "nat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
  },
  "details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Details"])},
    "req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Requirements"])},
    "qual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Type"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "det": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicants"])},
    "delAd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Job Posting"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "delDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete your job posting"])},
    "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Conditions"])}
  },
  "auth": {
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "phoneDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your mobile number here"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "passwordPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password here"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "haveAnAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification Code"])},
    "otpPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the verification code sent to your mobile number to change the mobile number"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "haveNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did not receive the code?"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
    "remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Time"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second"])},
    "conPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "conPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Password Confirmation"])},
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcome"])},
    "loginDis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your data to continue logging in"])},
    "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to the main page"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "cityPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your city"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "countryPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your country"])},
    "qual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree"])},
    "qualPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your degree"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General specialization"])},
    "fieldPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your general specialization"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialization"])},
    "specialPlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your specialization"])}
  },
  "main": {
    "curComp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Company"])},
    "infodef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification Information"])},
    "goChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go To Chat"])},
    "employ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["employ"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accept"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refuse"])},
    "cvs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded files"])},
    "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cv"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posted Jobs"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
    "ved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
    "addsall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add sallary"])},
    "startdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start date"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end date"])},
    "startdatePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter start date"])},
    "enddatePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter end date"])},
    "noapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no appliers"])},
    "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accept rate"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no data yet"])},
    "sendcomp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send complaint or suggestion"])},
    "cert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certificates"])},
    "infodefemp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Posting Information"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "applicant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant"])}
  }
}