<template>
    <!-- side bar  -->
    <sideBarVue />
    <!-- header  -->
    <dashHeaderVue />

    <section class="content px-3 pt-3" v-if="!loaderPack">
        <!-- dashboard  -->
        <homeViewVue :subscribtion="subscribtion" />
        <section id="subs" v-if="!subscribtion">




            <h4 class="mt-4 mb-4">الباقات</h4>
            <div class="row">
                <!-- single sub card -->
                <div class="col-md-4 mb-3" v-for="pkg in packages" :key="pkg.id">
                    <section class="position-relative single_sub pt-3 pb-2 px-3">
                        <!-- sub image  -->
                        <div class="sub_image mb-3">
                            <img :src="pkg.image" alt="subscribtion icon">
                        </div>
                        <!-- title  -->
                        <h6 class="fw-bold">
                            {{ pkg.title }}
                        </h6>
                        <!-- desc  -->
                        <p class="text-center grayColor">
                            {{ pkg.description }}
                        </p>

                        <span class="fw-6 fs-16">
                            {{ pkg.price }} ريال
                        </span>

                        <!-- input  -->
                        <input type="radio" name="pkg" class="checkPkg" @change="setPkg(pkg.id, pkg.free)"
                            :value="pkg.id">
                        <label for="" class="package_label">
                        </label>


                    </section>
                </div>

            </div>

            <div class="flex_center mb-5">
                <button class="main_btn w-50 pt-2 pb-2 mb-3" @click.prevent="addSubscription" :disabled="disabled">
                    <span v-if="!disabled">دفع الان</span>
                    <div class="spinner-border" role="status" v-if="disabled">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
            </div>

        </section>
    </section>
</template>

<script>
import sideBarVue from '@/components/layout/sideBar.vue';
import dashHeaderVue from '@/components/layout/dash_header.vue';
import homeViewVue from '@/components/dashboard/subscribeComonent.vue';
import axios from 'axios';
// import Toast from 'primevue/toast';


export default {

    data() {
        return {
            loaderPack: true,
            subscribtion: false,
            dataFetched: true,
            package_id: null,
            disabled: false,
            free: null,
            pay_type: false,
            payment_gate_link: '',
            pay: false
        }
    },
    computed: {
        packages() {
            return this.$store.state.packages;
        }
    },
    components: {
        sideBarVue,
        dashHeaderVue,
        homeViewVue
    },
    created() {
        // if( this.subscribtion == true ){
        //     setTimeout(() => {
        //     // Assuming `fetchPackages` is a method to fetch your data
        //     this.dataFetched = false; // Mark data as fetched
        //     }, 1000); // Adjust the delay as needed
        // }
        this.$store.dispatch('getPackages');
        // console.log(this.$store.state);
        
    },
    methods: {
  
        setPkg(id, type) {
            // console.log(id , type)
            this.package_id = id;
            this.free = type
        },
        async addSubscription() {
            if (this.free == true || this.free == false) {
                this.disabled = true;
                const fd = new FormData();
                fd.append('package_id', this.package_id);

                const token = localStorage.getItem('token');
                const headers = {
                    Authorization: `Bearer ${token}`,
                };

                await axios.post('company/subscription/add', fd, { headers })
                    .then((res) => {
                        if (res.data.key === 'success') {
                            this.$toast.add({ severity: 'success', summary: res.data.msg, life: 3000 });
                            this.disabled = false;
                            setTimeout(() => {
                                this.$router.push('/dashboard');
                            }, 1000);
                        } else {
                            this.$toast.add({ severity: 'error', summary: res.data.msg, life: 3000 });
                            this.disabled = false;
                        }
                    })
                    .catch((err) => {
                        this.$toast.add({ severity: 'error', summary: err, life: 3000 });
                        this.disabled = false;
                    })
            } else {
                this.pay_type = true;
            }

        },

        // setIsSub(isSub) {
        //     console.log(`=== ${isSub}`);
        //     this.subscribtion = isSub
            
        // },


        async updateSub() {
            this.disabled = true;
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const fd = new FormData();
            fd.append('card_type', this.payment_type);
            fd.append('package_id', this.package_id);
            await axios.post('company/package/payment', fd, { headers })
                .then((res) => {
                    if (res.data.key === 'success') {
                        // this.$toast.add({ severity: 'success', summary: res.data.msg, life: 3000 });
                        this.disabled = false;
                        this.pay_type = false;
                        setTimeout(() => {
                            this.pay = true;
                        }, 1000);
                        this.payment_gate_link = res.data.data.checkoutUrl;
                    } else {
                        this.$toast.add({ severity: 'error', summary: res.data.msg, life: 3000 });
                        this.disabled = false;
                    }
                })

        },


        getPackages(  ){
            this.loaderPack = true
      const token = localStorage.getItem('token');

      // Create headers object with Authorization header
      const headers = {
        Authorization: `Bearer ${token}`,
      };
       axios.get('packages' , {headers})
      .then( (res)=>{
        this.subscribtion = res.data.is_subscribed
        this.loaderPack = false
      } )
    },

    },
    mounted() {
        // this.setIsSub()
        this.getPackages()
    }
}
</script>

<style></style>